<template>
  <div class="app auth-page" id="auth" :style="authStyle">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card card-container mt-5">
            <h5>{{ 'settings.inviteUser' | translate }}</h5>
            <br />
            <form class="form-invite" @submit.prevent="completeInvitation">
              <password-input v-model="user.password" @isValid="(valid) => (isValidPassword = valid)" />
              <input
                type="password"
                id="inputConfirmPassword"
                class="form-control mt-1"
                :placeholder="$t('form.confirmPasswordPlaceholder')"
                required
                v-model="user.confirmPassword"
              />
              <select
                class="form-control mt-1"
                name="language-select"
                id="language-select"
                v-model="user.language"
                @change="updateLanguage"
                v-if="company && company.languages.length > 1"
              >
                <option :value="null" disabled>{{ 'settings.changeYourLanguage' | translate }}</option>
                <option v-for="language in company.languages" :value="language.localeCode" :key="language.id">
                  {{ language.languageName }}
                </option>
              </select>
              <br />
              <div class="checkbox mb-2 mt-1 ml-3 pl-1" v-if="company.licenseAgreementURL">
                <label>
                  <input type="checkbox" required v-model="user.acceptLicenseAgreement" />
                  <a :href="company.licenseAgreementURL" target="_blank">
                    {{ 'settings.acceptLicense' | translate }}
                  </a>
                </label>
              </div>
              <div class="checkbox ml-3 pl-1" v-if="!(this.company.hidePrivacyPolicyConsentOnLoginPages === true)">
                <label>
                  <input type="checkbox" required v-model="user.acceptPrivacyPolicy" />
                  <router-link to="/privacy-policy" target="_blank">
                    {{ 'general.acceptPrivacyPolicy' | translate }}
                  </router-link>
                </label>
              </div>
              <br />
              <primary-button class="btn-block mt-1" type="submit" :loading="loading">{{
                'settings.confirmAccount' | translate
              }}</primary-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import authMixin from '../../mixins/auth';
import { checkSyncedStatus } from '@app/http/authentication';
import { updateLanguage } from '@shared/translations';
const { VUE_APP_TALENTECH_AUTH } = process.env;

export default {
  mixins: [authMixin],
  mounted() {
    const { query } = this.$route;
    if (!query.email) {
      this.$notify({
        type: 'error',
        message: this.$t('notifications.missingUserEmail'),
        position: 'bottom-right'
      });
    } else if (!query.activationIdentifier) {
      this.$notify({
        type: 'error',
        message: this.$t('notifications.missingActivationId'),
        position: 'bottom-right'
      });
    }

    if (!query.email || !query.activationIdentifier) {
      return this.$router.push('/auth/login');
    }

    const { activationIdentifier, email } = query;

    checkSyncedStatus(this.company.id, email).then((response) => {
      const synced = response.data.synced;
      this.loading = false;
      if (!synced) {
        this.user.email = email;
        this.user.activationIdentifier = activationIdentifier;
      } else {
        this.redirectToTalentechLogin();
      }
    });
  },
  data() {
    return {
      loading: true,
      showModal: false,
      onboardings: [],
      isValidPassword: false
    };
  },
  computed: {
    ...mapGetters('settings', ['company', 'subdomain'])
  },
  methods: {
    redirectToTalentechLogin() {
      window.open(
        `${VUE_APP_TALENTECH_AUTH}?url=${window.location.host}/auth/login&subdomain=${
          this.subdomain
        }&email=${encodeURIComponent(this.$route.query.email)}`,
        '_self'
      );
    },
    completeInvitation() {
      if (this.user.password !== this.user.confirmPassword) {
        return this.$notify({
          type: 'error',
          message: this.$t('notifications.passwordsDontMatch'),
          position: 'bottom-right'
        });
      } else if (!this.isValidPassword) {
        return this.$notify({
          type: 'error',
          message: this.$t('password.passwordFormatIncorrect'),
          position: 'bottom-right'
        });
      } else {
        this.$http
          .post('/inviteuser', {
            ...this.user,
            language: this.user.language || this.$i18n.locale(),
            subdomain: this.$store.getters['settings/subdomain']
          })
          .then(
            () => {
              window.localStorage.removeItem('invite-user-language');
              this.login();
              //Planhat activate user failed: Todo setup the company id to necessary id
              this.track('tal_activate_user_succeeded');
            },
            (err) => {
              this.loading = false;
              if (err.error) {
                this.$notify({
                  type: 'error',
                  message: err.error,
                  position: 'bottom-right'
                });
                //Planhat activate user failed: Todo setup the company id to necessary id
                this.track('tal_activate_user_failed');
              } else {
                this.$notify({
                  type: 'error',
                  message: this.$t('notifications.changePasswordError'),
                  position: 'bottom-right'
                });
              }
            }
          );
      }

      this.loading = true;
    },
    updateLanguage() {
      const languageId = this.company.languages.find((language) => language.localeCode === this.user.language)
        .languageId;

      if (window.localStorage.getItem('user') !== null) {
        let user = JSON.parse(window.localStorage.getItem('user'));
        user.language = this.user.language;
        user.languageId = languageId;
        window.localStorage.setItem('user', JSON.stringify(user));
      } else {
        const user = {
          language: this.user.language,
          languageId
        };
        window.localStorage.setItem('user', JSON.stringify(user));
      }
      window.localStorage.setItem('defaultLanguage', this.user.language);
      updateLanguage(this.user.language);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './auth.scss';
</style>
