<template>
  <div class="login auth-page" id="auth" :style="authStyle" v-if="!showLoader">
    <div class="container" v-if="company">
      <div class="row">
        <div class="col-12">
          <div class="card card-container mt-5" v-if="!hideLogin">
            <h2>{{ 'login.loginToYourAccount' | translate }}</h2>
            <form class="form-signin" @submit.prevent="login">
              <span id="reauth-email" class="reauth-email"></span>
              <input
                name="email"
                type="email"
                autocomplete="email"
                id="inputEmail"
                class="form-control"
                :placeholder="$t('login.enterEmail')"
                required
                autofocus
                v-model="user.email"
                aria-label="email"
                :disabled="stepTwoLogin"
              />
              <div v-if="stepTwoLogin" class="backStep1">
                <a href="#" @click="changeEmail">{{ $t('login.changeEmail') }}</a>
              </div>
              <input
                name="password"
                type="password"
                autocomplete="password"
                id="inputPassword"
                class="form-control mt-3"
                :placeholder="$t('form.passwordPlaceholder')"
                required
                v-model="user.password"
                aria-label="password"
                v-show="stepTwoLogin"
              />
              <select
                autocomplete="nope"
                class="form-control mt-3"
                name="language-select"
                id="language-select"
                v-model="user.language"
                @change="updateLanguage"
                v-if="company && company.languages.length > 1"
                aria-label="language"
              >
                <option :value="null" disabled>{{ 'settings.changeYourLanguage' | translate }}</option>
                <option v-for="language in company.languages" :value="language.localeCode" :key="language.languageId">
                  {{ language.languageName }}
                </option>
              </select>
              <br />

              <button class="btn btn-primary btn-block btn-signin btn-lg" v-if="!stepTwoLogin" @click="next">
                {{ $t('ui.next') }}
              </button>
              <primary-button
                v-if="stepTwoLogin"
                :loading="loading"
                size="large"
                type="submit"
                class="btn-block btn-signin"
                >{{ 'login.login' | translate }}</primary-button
              >
            </form>
            <br />
            <router-link v-if="stepTwoLogin" to="forgot-password" class="auth-link float-left">{{
              'login.forgotYourPassword' | translate
            }}</router-link>
            <div
              :class="{ 'mt-2': true, 'd-none': this.company.hidePrivacyPolicyConsentOnLoginPages === true }"
              v-html="loginFooterText"
            ></div>
          </div>
        </div>
      </div>

      <div v-if="loading && hideLogin" class="loader-container">
        <loader></loader>
      </div>
      <sweet-modal :title="$t('login.chooseApp')" :blocking="true" width="400" ref="template-modal" id="template-modal">
        <div class="modal-body">
          <div class="row">
            <div
              class="col-md-4 text-center pointer"
              v-for="onboarding in onboardings"
              :key="onboarding.id"
              @click="selectOnboarding(onboarding)"
            >
              <img
                v-if="onboarding.template.imageURL"
                :src="onboarding.template.imageURL"
                :alt="onboarding.template.name"
                class="img-fluid"
                width="100%"
                height="auto"
              />
              <img
                v-else
                src="../../../shared/assets/img/app-placeholder.png"
                :alt="onboarding.template.name"
                class="img-fluid"
                width="100%"
                height="auto"
              />
              <h3>{{ onboarding.template.name }}</h3>
            </div>
          </div>
        </div>
        <div slot="modal-footer" class="modal-footer"></div>
      </sweet-modal>
    </div>
  </div>
  <div class="pt-24" v-else>
    <loader></loader>
  </div>
</template>

<script>
import Vue from 'vue';
import authMixin from '../../mixins/auth';
import { mapGetters } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';
import PrimaryButton from '@shared/components/global/PrimaryButton.vue';
import { checkSyncedStatus } from '@app/http/authentication';
import { updateLanguage } from '@shared/translations';
const { VUE_APP_TALENTECH_AUTH } = process.env;

export default {
  components: {
    PrimaryButton,
    SweetModal
  },
  mixins: [authMixin],
  mounted() {
    if (this.$route.query.expired) {
      this.$notify({
        type: 'error',
        message: 'Session Expired',
        position: 'bottom-right'
      });
    }

    let user;
    if (window.localStorage.getItem('user') !== null) {
      user = JSON.parse(window.localStorage.getItem('user'));
    }

    if (window.localStorage.getItem('user') !== null) {
      user = JSON.parse(window.localStorage.getItem('user'));
    }
  },
  created() {
    let talentechIDToken = this.$route.query['tid-token'];
    if (talentechIDToken) {
      this.hideLogin = true;
      this.loading = true;
      talentechIDToken = atob(talentechIDToken);
      this.loginWithTalentechToken(talentechIDToken);
      return;
    }

    const { email, password, authenticatorToken } = this.$store.state.auth.twoFactorSetup;
    if (email && password && authenticatorToken) {
      this.hideLogin = true;
      this.loading = true;
      this.user.email = email;
      this.user.password = password;

      const user = {
        ...this.user,
        subdomain: this.$store.getters['settings/subdomain']
      };

      this.$store.dispatch('auth/setTwoFactorSetup', {
        token: null,
        email: null,
        password: null,
        authenticatorToken: null
      });
      this.$http.post('auth', user, {}).then((response) => {
        this.twoFactorLogin(authenticatorToken, response.data.token);
      });
    } else if (email && password) {
      this.user.email = email;
      this.user.password = password;
      this.$store.dispatch('auth/setTwoFactorSetup', {
        token: null,
        email: null,
        password: null,
        authenticatorToken: null
      });
      this.login();
    }
  },
  data() {
    return {
      showLoader: false,
      loading: false,
      stepTwoLogin: false
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('settings', ['subdomain']),
    loginFooterText() {
      let footerText = this.$t('login.agreeToTerms');
      if (this.company.licenseAgreementURL) {
        footerText = footerText.replace(
          '{termsAndConditions}',
          '<a style="color: #444444;text-decoration: underline;" href="' +
            this.company.licenseAgreementURL +
            '" target="_blank">' +
            this.$t('general.termsAndConditions') +
            '</a>'
        );
        footerText = footerText.replace(
          '{privacyPolicy}',
          '<a style="color: #444444;text-decoration: underline;" href="/privacy-policy" target="_blank">' +
            this.$t('footer.privacyPolicy') +
            '</a>'
        );
      } else {
        footerText = footerText.replace('{termsAndConditions}', this.$t('general.termsAndConditions'));
        footerText = footerText.replace(
          '{privacyPolicy}',
          '<a style="color: #444444;text-decoration: underline;" href="/privacy-policy" target="_blank">' +
            this.$t('footer.privacyPolicy') +
            '</a>'
        );
      }
      return footerText;
    }
  },
  methods: {
    updateLanguage() {
      const languageId = this.company.languages.find((language) => language.localeCode === this.user.language)
        .languageId;

      if (window.localStorage.getItem('user') !== null) {
        let user = JSON.parse(window.localStorage.getItem('user'));
        user.language = this.user.language;
        user.languageId = languageId;
        window.localStorage.setItem('user', JSON.stringify(user));
      } else {
        const user = {
          language: this.user.language,
          languageId
        };
        window.localStorage.setItem('user', JSON.stringify(user));
      }
      window.localStorage.setItem('defaultLanguage', this.user.language);
      updateLanguage(this.user.language);
    },
    next(event) {
      event.preventDefault();
      if (this.user.email) {
        checkSyncedStatus(this.company.id, this.user.email).then((response) => {
          const synced = response.data.synced;
          if (!synced) {
            this.stepTwoLogin = true;
            this.focusField('#inputPassword');
          } else {
            this.redirectToTalentechLogin();
          }
        });
      }
    },
    changeEmail(e) {
      e.preventDefault();
      this.user.password = '';
      this.stepTwoLogin = false;
      this.focusField('#inputEmail');
    },
    redirectToTalentechLogin() {
      window.open(
        `${VUE_APP_TALENTECH_AUTH}?url=${window.location.host + window.location.pathname}&subdomain=${
          this.subdomain
        }&email=${encodeURIComponent(this.user.email)}`,
        '_self'
      );
    },
    focusField(query) {
      setTimeout(() => {
        document.querySelector(query).focus();
      }, 10);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './auth.scss';

.btn.btn-lg {
  font-size: var(--subtitle-size);
}

#auth .backStep1 {
  text-align: right;
  padding: 2px 0;
  font-size: var(--text-size-small);
  text-decoration: underline;
  color: #444444;
  position: absolute;
  right: 0;
  z-index: 5;
  a {
    color: #444444;
  }
}

input:disabled {
  cursor: not-allowed;
}

#auth select,
input[type='email'],
input[type='password'] {
  padding: 0 4px !important;
}

form {
  position: relative;
}

#auth .btn {
  cursor: pointer !important;
}
</style>

<style>
.Cookie__buttons {
  min-width: 230px;
  text-align: center;
}

.login {
  min-height: calc(100vh - 68px);
}

@media (min-width: 768px) {
  .login {
    min-height: calc(100vh - 85px);
  }
}
</style>
